/* eslint-disable no-undef */
import { useEffect } from 'react';
import { navigate } from 'gatsby';
import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://f5423b3c6152469b96578abad870fd77@o1128939.ingest.sentry.io/4504903216267264',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en';
  }

  const lang =
    navigator &&
    navigator.language &&
    navigator.language.split('-')[0];
  if (!lang) return 'en';

  switch (lang) {
    case 'de':
      return 'de';
    default:
      return 'en';
  }
};

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    navigate(`/${urlLang}`);
  }, []);

  return null;
};

export default IndexPage;
